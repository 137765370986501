import axios from "axios";
import { useEffect, useState } from "react";
import { API_BASE_URL } from "../../configuration/Configuration";
import {
  ListItemButton,
  List,
  Tooltip,
  IconButton,
  Box,
  Autocomplete,
  Button,
  TextField,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
const Department = ({ dep, userType }) => {
  const [departmentInfo, setDepartmentInfo] = useState();
  const [creatingMember, setCreatingMember] = useState(false);
  const [creatingLane, setCreatingLane] = useState(false);
  const [userName, setUserName] = useState();
  const [options, setOptions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [lanes, setLanes] = useState();
  const [km, setKm] = useState();
  const [margin, setMargin] = useState()
  const [price, setPrice] = useState();

  useEffect(() => {
    if (departmentInfo?.users) {
      const userArray = [];
      for (const user of departmentInfo.users) {
        userArray.push({ id: user.id, label: user.username });
      }
      setOptions(userArray);
    }
  }, [departmentInfo]);

  useEffect(() => {
    if (dep.id) {
      axios
        .get(`${API_BASE_URL}/api/department/${dep.id}`)
        .then((res) => {
          setDepartmentInfo(res.data);
          setOptions(res.data.users);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .get(`${API_BASE_URL}/api/department/countries/`)
        .then((res) => {
          setCountries(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [dep]);

  const removeMember = (id) => {
    if (id) {
      axios
        .post(`${API_BASE_URL}/api/department/remove-user/`, {
          user_id: id,
          department_id: dep.id,
        })
        .then((res) => {
          setDepartmentInfo({ ...departmentInfo, members: res.data.members });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const removeLane = (id) => {
    if (id) {
      axios
        .post(`${API_BASE_URL}/api/department/remove-lane/`, {
          lane_id: id,
          department_id: dep.id,
        })
        .then((res) => {
          setDepartmentInfo({ ...departmentInfo, lanes: res.data.lanes });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const addLanes = (e) => {
    e.preventDefault();

    if (lanes.origin && lanes.destination) {
      if (lanes.id) {
        axios
          .post(`${API_BASE_URL}/api/department/update-lanes/${lanes.id}/`, {
            originCountry: lanes.origin,
            destinationCountry: lanes.destination,
            department_id: dep.id,
            suggested_price: price,
            empty_km: km,
            margin: margin
          })
          .then((res) => {
            setDepartmentInfo({ ...departmentInfo, lanes: res.data.lanes });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .post(`${API_BASE_URL}/api/department/lanes/`, {
            originCountry: lanes.origin,
            destinationCountry: lanes.destination,
            department_id: dep.id,
            suggested_price: price,
            empty_km: km,
            margin: margin

          })
          .then((res) => {
            setDepartmentInfo({ ...departmentInfo, lanes: res.data.lanes });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  const addMember = (e) => {
    e.preventDefault();

    const user = options.filter((el) => el.label === userName);
    if (user) {
      axios
        .post(`${API_BASE_URL}/api/department/add/`, {
          user_id: user[0].id,
          department_id: dep.id,
        })
        .then((res) => {
          setDepartmentInfo({ ...departmentInfo, members: res.data.members });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const editLane = (lane) => {
    setLanes(lane);
    setPrice(lane.suggested_price ? lane.suggested_price : "");
    setKm(lane.empty_km ? lane.empty_km : "");
    setMargin(lane.margin ? lane.margin: "")
    setCreatingLane(true);
  };
  return (
    <div>
      <p>Selected Department: {dep.name}</p>
      <div className="depWrapper">
        <div className="depWrapper-inner">
          <p className="title">Members</p>
          <List>
            {(userType.isAdmin || userType.isStaff) && (
              <ListItemButton
                onClick={() => {
                  setCreatingMember(true);
                  setCreatingLane(false);
                }}
              >
                + Add Member
              </ListItemButton>
            )}

            {departmentInfo?.members &&
              departmentInfo.members.map((el) => {
                return (
                  <ListItemButton>
                    {el.username}
                    <Tooltip
                      onClick={() => removeMember(el.id)}
                      sx={{ marginLeft: "auto" }}
                      title="Delete"
                      size="small"
                    >
                      <IconButton>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </ListItemButton>
                );
              })}
          </List>
        </div>
        <div className="depWrapper-inner">
          <p className="title">Lanes</p>
          <List>
            <ListItemButton
              onClick={() => {
                setCreatingLane(true);
                setCreatingMember(false);
                setLanes();
                setKm("");
                setPrice("");
                setMargin("")
              }}
            >
              + Add Lane
            </ListItemButton>
            {departmentInfo?.lanes &&
              departmentInfo.lanes.map((el) => {
                return (
                  <ListItemButton>
                    {el.origin} - {el.destination}{" "}
                    <span
                      style={{
                        marginLeft: "30px",
                        fontSize: "12px",
                        width: "100px",
                      }}
                    >
                      {el?.suggested_price &&
                        `Price: ${el.suggested_price}€/km`}
                    </span>
                    <span style={{ marginLeft: "15px", fontSize: "12px" }}>
                      {el?.empty_km && `Empty: ${el.empty_km}km`}
                    </span>
                    <span style={{ marginLeft: "15px", fontSize: "12px" }}>
                      {el?.margin && `Margin: ${el.margin}`}
                    </span>
                    <Tooltip
                      onClick={() => editLane(el)}
                      sx={{ marginLeft: "auto" }}
                      title="Edit"
                      size="small"
                    >
                      <IconButton>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      onClick={() => removeLane(el.id)}
                      title="Delete"
                      size="small"
                    >
                      <IconButton>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </ListItemButton>
                );
              })}
          </List>
        </div>
      </div>
      <div className="createArea">
        {creatingMember && (
          <div>
            <p>Add Member to Department</p>
            <div className="creating">
              <Box
                textAlign="left"
                component="form"
                alignItems="center"
                display="flex"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <Autocomplete
                  required
                  id="userName"
                  size="small"
                  options={options}
                  onInputChange={(event, value) => {
                    setUserName(value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Username" />
                  )}
                />
                <Button
                  type="submit"
                  onClick={addMember}
                  variant="contained"
                  sx={{ marginLeft: "20px" }}
                >
                  Add
                </Button>
              </Box>
            </div>
          </div>
        )}

        {creatingLane && (
          <div>
            <p>Add Lanes For Department</p>
            <div className="creating">
              <Box
                textAlign="left"
                component="form"
                alignItems="center"
                display="flex"
                flexWrap="wrap"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: "25ch" },
                }}
                noValidate
                autoComplete="off"
              >
                <Autocomplete
                  required
                  id="origin"
                  size="small"
                  options={countries}
                  value={lanes?.origin}
                  onInputChange={(event, value) => {
                    setLanes({ ...lanes, origin: value });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="From" />
                  )}
                />
                <Autocomplete
                  required
                  id="destination"
                  size="small"
                  options={countries}
                  value={lanes?.destination}
                  onInputChange={(event, value) => {
                    setLanes({ ...lanes, destination: value });
                  }}
                  renderInput={(params) => <TextField {...params} label="To" />}
                />
                <Box sx={{ width: "100%" }}></Box>
                <TextField
                  required
                  id="name"
                  size="small"
                  type="number"
                  value={km}
                  onInput={(e) => setKm(e.target.value)}
                  label="Empty KM"
                />
                <TextField
                  required
                  id="name"
                  size="small"
                  inputProps={{ step: "0.01", shrink: !!price }}
                  value={price}
                  type="number"
                  onInput={(e) => setPrice(e.target.value)}
                  label="Suggested Price"
                />
                <Box sx={{ width: "100%" }}></Box>
                <TextField
                  required
                  id="margin"
                  size="small"
                  type="text"
                  value={margin}
                  onInput={(e) => setMargin(e.target.value)}
                  label="Margin"
                />
                <Box sx={{ width: "100%", marginTop: "20px" }}></Box>

                <Button
                  type="submit"
                  onClick={addLanes}
                  variant="contained"
                  sx={{ marginLeft: "10px" }}
                >
                  {lanes?.id ? "Edit" : "Add"}
                </Button>
              </Box>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Department;
