import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { Route, Routes } from "react-router";
import { pages, adminPages } from "../configuration/Pages";
import axios from "axios";
import NewTender from "./NewTender";
import AdvanceTender from "./AdvanceTender";
import EditTender from "./EditTender";
import Import from "./Import";
// import Analytics from './Analytics';
import Export from "./Export";
import TenderOverview from "./TenderOverview";
import Admin from "./Admin";
import Login from "./Login";
import Logout from "./Logout";
import Departments from "./Pages/Departments";

import { API_BASE_URL } from "../configuration/Configuration";
const linkStyle = {
  textDecoration: "none",
  color: "black",
};

const headerLinkStyle = {
  textDecoration: "none",
  color: "white",
  padding: "0",
  margin: "0",
};

const drawerWidth = 240;

function ResponsiveDrawer() {
  const [userType, setUser] = useState({
    isAdmin: false,
    isRegular: false,
    isStaff: false,
    isChecks: false,
  });
  const [user, setUserName] = useState();
  const [isAuth, setIsAuth] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("access_token") !== null) {
      setIsAuth(true);
      let tempUser = localStorage.getItem("user");

      axios.get(`${API_BASE_URL}/api/is-admin/${tempUser}`).then((res) => {
        const isAdmin = res.data.is_admin;
        const isStaff = res.data.is_staff;
        const isRegular = res.data.is_regular;
        setUser({ isAdmin, isStaff, isRegular });
      });
      setUserName(localStorage.getItem("user"));
    }
  }, [isAuth]);

  const navigate = useNavigate();

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      {isAuth && (
        <List>
          {pages.map((pages) => (
            <ListItem key={pages.name} disablePadding>
              <ListItemButton
                sx={{ width: drawerWidth }}
                selected={
                  window.location.pathname === pages.path ||
                  (window.location.pathname === "/" &&
                    pages.path === "/AdvanceTender")
                }
                onClick={() => {
                  navigate(pages.path, { state: { id: "reload" } });
                }}
              >
                <ListItemIcon>{pages.icon}</ListItemIcon>
                <ListItemText primary={pages.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
      {(userType.isAdmin) && <Divider />}
      {(userType.isAdmin) && (
        <List>
          {adminPages.map((pages) => (
            <ListItem key={pages.name} disablePadding>
              <ListItemButton
                sx={{ width: drawerWidth }}
                selected={window.location.pathname === pages.path}
                onClick={() => {
                  navigate(pages.path);
                }}
              >
                <ListItemIcon>{pages.icon}</ListItemIcon>
                <ListItemText primary={pages.name} />
              </ListItemButton>
              {/* </Link> */}
            </ListItem>
          ))}
        </List>
      )}
      {isAuth ? <Divider /> : null}
      {!isAuth ? (
        <List>
          {["Login"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <Link to={`/login`} style={linkStyle}>
                <ListItemButton sx={{ width: drawerWidth }}>
                  <ListItemIcon>
                    <LoginIcon />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
      ) : (
        <List>
          {["Logout"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <Link to={`/logout`} style={linkStyle}>
                <ListItemButton sx={{ width: drawerWidth }}>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
      )}
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: "#a2232b" }}
      >
        <Toolbar style={{ padding: "0", margin: "0" }}>
          <Link to={`/`} style={{ margin: "0", padding: "0" }}>
            <div style={{ width: drawerWidth }}>
              <img
                src="/logosvg.svg"
                height="70"
                className="queLogo"
                alt="Logo Quehenberg"
              />
            </div>
          </Link>

          <Link to={`/`} style={headerLinkStyle}>
            <Typography variant="h6" noWrap component="div">
              Tender Management Tool
            </Typography>
          </Link>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {" "}
          </Typography>
          {isAuth && <AccountCircleIcon />}
          <div style={{ width: "10px" }}></div>
          {isAuth && (
            <Typography variant="h6" noWrap component="div">
              {user}
            </Typography>
          )}
          <div style={{ width: "10px" }}></div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
          }}
          open
        >
          {drawer}
          <List className="bottomPush"></List>
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {/* TODO add Routing here */}
        <Routes>
          {!isAuth && <Route path="/" element={<Login />} />}
          {isAuth && <Route path="/" element={<AdvanceTender />} />}
          <Route path="NewTender" element={isAuth && <NewTender />} />
          <Route path="Import" element={isAuth && <Import />} />
          <Route path="AdvanceTender" element={isAuth && <AdvanceTender />} />
          <Route path="EditTender" element={isAuth && <EditTender />} />
          {/*<Route path="Analytics" element={
          (isAuth) && (<Analytics />)} />*/}
          <Route path="Export" element={isAuth && <Export />} />
          <Route path="TenderOverview" element={isAuth && <TenderOverview />} />
          <Route
            path="Admin"
            element={userType.isAdmin  && <Admin />}
          />

          <Route
            path="departments"
            element={isAuth && <Departments userType={userType} />}
          />

          <Route path="Login" element={<Login />} />
          <Route path="Logout" element={<Logout />} />
        </Routes>
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;
