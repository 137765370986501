import React, {
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import { AgGridReact } from "ag-grid-react";

import {
  API_URL_LANES_PRICING,
  API_URL_PRICES_UPDATE,
  API_URL_STATE_UPDATE,
  API_URL_TENDERS,
  API_URL_TENDERS_DELETE,
  API_URL_STATUS,
  API_BASE_URL,
  API_URL_TENDERS_EXPORT,
  tenderColumnsDefinitionOverview,
} from "../../configuration/Configuration";
import {
  isPosted,
  headerColumns,
  setGridColsFeedback,
} from "../../configuration/Utils";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";

import { useLocation, useSearchParams } from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import TableControls from "../../integrations/core/table/TableControls";

let formData = {};
let formDataCustomerRel = {};
let formDataCustomerAbs = {};

let gridOptions = {
  rowClassRules: {
    "final-price-set": (params) => {
      const value = params?.data?.final;
      return value != null && !isNaN(value) && value > 0;
    },
  },
};

const LanePricing = (props) => {
  const ref = useRef();
  let [searchParams, setSearchParams] = useSearchParams();

  const { key } = useLocation();
  const [user, setUser] = useState({
    isAdmin: false,
    isRegular: false,
    isStaff: false,
  });

  useEffect(() => {
    reset();
  }, [key]);

  async function fetchData() {
    //FETCH USER
    let tempUser = localStorage.getItem("user");

    const userData = (
      await axios.get(`${API_BASE_URL}/api/is-admin/${tempUser}`)
    ).data;
    const isAdmin = userData.is_admin;
    const isStaff = userData.is_staff;
    const isRegular = userData.is_regular;
    setUser({ isAdmin, isStaff, isRegular });

    let filterValue = "";
    if (user.isAdmin) {
      filterValue = "a";
    } else {
      filterValue = "aR";
    }
    const data1 = (
      await axios.get(
        props.tender_input + "filtered/" + filterValue + "/" + props.user
      )
    ).data;
    let cols = tenderColumnsDefinitionOverview;
    if (props.user.indexOf("regional") >= 0) {
      cols = [
        {
          field: "regionalStatus",
          headerName: "Prices Set",
          resizable: true,
          filter: true,
          sortable: true,
          valueFormatter: (value) => {
            return value.value === true ? "\u2714" : "\u2718";
          },
        },
      ].concat(cols);
    }
    setTenderData1(data1);

    const data2 = (
      await axios.get(props.tender_input + "filtered/ACR/" + props.user)
    ).data;
    setTenderData2(data2);
    setGridCols(cols);
  }

  const reset = () => {
    handleUnload();
    fetchData();
  };

  // has tender ID been selected state
  const [tenderState, setTenderState] = useState(false);
  const [changesDone, setChangesDone] = useState(false);
  const [changesDoneStatus, setChangesDoneStatus] = useState(true);
  const [tenderStatus, setTenderStatus] = useState("");
  const [tenderData, setTenderData] = useState([]);
  const [tenderData1, setTenderData1] = useState([]);
  const [tenderData2, setTenderData2] = useState([]);
  const [tenders, setTender] = useState("");
  const [pricesCompleted, setPricesCompleted] = useState(true);
  const [checked, setChecked] = useState("close0");
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState("");
  const [requested_capacity, setRequestedCapacity] = useState("");
  const [checksComplete, setChecksComplete] = useState(true);
  const [gridStyle, setGridStyle] = useState({
    height: 720,
    width: "100%",
    marginBottom: "40px",
    textAlign: "center",
  });

  const gridRef1 = useRef();
  const gridRef2 = useRef();
  const gridRef3 = useRef();
  const gridRef4 = useRef();

  let fullScreenHandle1 = useFullScreenHandle();
  let fullScreenHandle2 = useFullScreenHandle();
  let fullScreenHandle3 = useFullScreenHandle();
  let fullScreenHandle4 = useFullScreenHandle();

  const reportChange = useCallback(
    (state, handle) => {
      if (state) {
        setGridStyle({
          height: window.innerHeight + 50,
          width: "100%",
          marginBottom: "40px",
          textAlign: "center",
        });
      } else {
        setGridStyle({
          height: 720,
          width: "100%",
          marginBottom: "40px",
          textAlign: "center",
        });
      }
    },
    [fullScreenHandle1, fullScreenHandle2, fullScreenHandle4]
  );

  const [gridCols, setGridCols] = useState([]);
  const [gridColsF, setGridColsF] = useState([]);

  function autoSizeAll(gridApi, skipHeader) {
    const allColumnIds = [];
    gridApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });

    gridApi.autoSizeColumns(allColumnIds, skipHeader);
  }

  const onColumnChange = useCallback(() => {
    if (gridRef3?.current?.columnApi) {
      autoSizeAll(gridRef3?.current?.columnApi, true);
    }
    if (gridRef2?.current?.columnApi) {
      autoSizeAll(gridRef2?.current?.columnApi, true);
    }
    if (gridRef1?.current?.columnApi) {
      autoSizeAll(gridRef1?.current?.columnApi, true);
    }
    if (gridRef4?.current?.columnApi) {
      autoSizeAll(gridRef4?.current?.columnApi, true);
    }
    gridRef1?.current?.columnApi?.autoSizeAllColumns();
    gridRef2?.current?.columnApi?.autoSizeAllColumns();
    gridRef3?.current?.columnApi?.autoSizeAllColumns();
    gridRef4?.current?.columnApi?.autoSizeAllColumns();
  });

  // reset states before unload
  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);

    return () => window.removeEventListener("beforeunload", handleUnload);
  }, []);

  const handleUnload = () => {
    setTenderState(false);
    setChangesDone(false);
    setChecksComplete(false);
    setChangesDoneStatus(false);
    setChecked("close0");
  };

  const handleChangeSwitch = (event) => {
    setChecked(event.target.value);
  };

  const setUrlParams = (id) => {
    const url = new URL(window.location.href);
    url.searchParams.set("tender_id", id);
    window.history.pushState(null, "", url.toString());
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const tender_id = url.searchParams.get("tender_id");
    if (tender_id) {
      const params = { data: { id: tender_id } };
      setTimeout(() => {
        getTenderData(params);
      }, "500");
    }
  }, []);

  const getTenderData = (params) => {
    setUrlParams(params.data.id);

    setTender(params.data.id);
    setIsLoading(true);
    let tenderID = params.data.id;
    (async () => {
      const url_api = API_URL_LANES_PRICING + tenderID + "/" + props.user;
      const { data } = await axios.get(url_api);
      setCompany(data.tender[0].company);
      setRequestedCapacity(data.tender[0].requested_capacity);
      //SET IT HERE
      setTenderStatus(data.tender[0].tender_status);
      setTenderData(data.rows);
      let allPricesSet = true;
      data.rows.forEach((row) => {
        const value = row.final;
        let isFinalPriceSet = value != null && !isNaN(value) && value > 0;
        if (!isFinalPriceSet) {
          allPricesSet = false;
        }
      });
      setPricesCompleted(allPricesSet);
      const url_api_checks = API_URL_TENDERS + tenderID;
      let data_checks = await axios.get(url_api_checks);
      data_checks = data_checks.data["0"];

      // update pricing checkbox
      let status = data.tender[0].tender_status;

      if (
        data_checks.legal_check &&
        data_checks.finance_check &&
        data_checks.qsu_check
      ) {
        setChecksComplete(true);
      } else {
        setChecksComplete(false);
      }
      let header = await headerColumns(tenderID);
      setGridCols(header);
      let headerF = await setGridColsFeedback(tenderID);
      setGridColsF(headerF);
      setIsLoading(false);
    })();
    setTenderState(true);

    formData = {};
  };

  const onCellValueChanged = (params) => {
    setChangesDone(true);
    if (params.colDef.field.indexOf("customer_rel") >= 0) {
      formDataCustomerRel[params.data.id] = { value: params.newValue };
    } else if (params.colDef.field.indexOf("customer_abs") >= 0) {
      formDataCustomerAbs[params.data.id] = { value: params.newValue };
    } else {
      formData[params.data.id] = { value: params.newValue };
    }
    let allPricesSet = true;
    let gridApi = params.api;
    gridApi.forEachNodeAfterFilterAndSort((node) => {
      const value = node?.data?.final;
      let isFinalPriceSet = value != null && !isNaN(value) && value > 0;
      if (!isFinalPriceSet) {
        allPricesSet = false;
      }
    });
    setPricesCompleted(allPricesSet);
    setChangesDoneStatus(allPricesSet);
    postPrices(params.colDef.field);
  };

  const postPrices = async (field) => {
    let pricetype;
    console.log(field);
    if (props.price_type && tenderStatus === "Round 1 Prices") {
      pricetype = props.price_type;
    } else if (props.price_type && tenderStatus === "Round 2 Prices") {
      pricetype = props.price_type + "-2";
    } else if (props.price_type && tenderStatus === "Round 3 Prices") {
      pricetype = props.price_type + "-3";
    } else if (tenderStatus === "Round 2 Prices") {
      pricetype = props.price_type_r2;
    } else if (tenderStatus === "Round 3 Prices") {
      pricetype = props.price_type_r3;
    } else if (tenderStatus === "Round 1 Prices") {
      pricetype = props.price_type;
    } else {
      pricetype = "";
    }
    if (field === "final") {
      pricetype = "final";
    }
    if (field === "capacity") {
      pricetype = "capacity";
    }
    if (field === "transit_time") {
      pricetype = "transit_time";
    }
    if (field === "additional_costs") {
      pricetype = "additional_costs";
    }
    if (field === "margin") {
      pricetype = "margin";
    }
    if (field === "additionalData_y") {
      pricetype = "additionalData_y";
    }

    if (
      tenderStatus === "Round 2 Feedback" ||
      tenderStatus === "Round 3 Feedback"
    ) {
      let priceData = {
        customer_rel: formDataCustomerRel,
        customer_abs: formDataCustomerAbs,
      };
      let statusH = (await axios.get(API_URL_STATUS + tenders)).data;
      await axios
        .post(
          API_URL_PRICES_UPDATE +
            "customerFeedback" +
            statusH +
            "/" +
            tenders +
            "/" +
            props.user,
          priceData
        )
        .then((res) => console.log("Tender Price Update posted", res.data));
      formDataCustomerRel = {};
      formDataCustomerAbs = {};
    } else {
      if (field === "empty-km") {
        const key = Object.keys(formData)[0];
        formData[key]["key"] = "empty-km";
      }

      await axios
        .post(
          API_URL_PRICES_UPDATE + pricetype + "/" + tenders + "/" + props.user,
          formData
        )
        .then((res) => console.log("Tender Price Update posted", res.data));
    }
    setChangesDone(false);
    formData = {};
  };

  const confirmPrices = async () => {
    const submitData = {};
    submitData["tenderID"] = tenders;

    const updateData = [];
    for (const lane of tenderData) {
      updateData.push(lane.id);
    }
    submitData["prices_for_lanes"] = updateData;
    submitData['user'] = props.user 

    
    await axios
      .post(`${API_BASE_URL}/api/tender/confirm-prices/`, submitData)
      .then((res) => console.log("Tender State Update posted", res.data));
    //TODO
    
    // reset();
  };

  const updateState = async () => {
    let pushData = {};
    let changes = { round2: { value: null } };

    if (checked === "advance2" && tenderStatus === "Round 1 Finalized") {
      changes.round2.value = "Round 2 Feedback";
    } else if (checked === "close0" && tenderStatus === "Round 1 Finalized") {
      changes.round2.value = "Closed";
    } else if (checked === "advance3" && tenderStatus === "Round 2 Finalized") {
      changes.round2.value = "Round 3 Feedback";
    } else if (checked === "close0" && tenderStatus === "Round 2 Finalized") {
      changes.round2.value = "Closed";
    } else if (checked === "accept0") {
      changes.round2.value = "Accepted";
    } else {
      changes.round2.value = "Closed";
    }
    pushData["changesBackend"] = changes;
    pushData["tenderID"] = tenders;
    if (
      tenderStatus === "Round 1 Finalized" ||
      tenderStatus === "Round 2 Finalized"
    ) {
      pushData["changesBackend"]["prices"] = {
        value: "False",
        type: "advance_round2",
      };
    }
    if (
      window.confirm(
        "Do you really want to advance the tender to status '" +
          changes["round2"]["value"] +
          "'?"
      )
    ) {
      await axios
        .post(API_URL_STATE_UPDATE, pushData)
        .then((res) => console.log("Tender State Update posted", res.data));
      reset();
    }
  };

  const closeTender = async () => {
    let pushData = {};
    let changes = { round2: { value: "Closed" } };
    pushData["changesBackend"] = changes;
    pushData["tenderID"] = tenders;
    if (window.confirm("Do you really want to close the tender?")) {
      await axios
        .post(API_URL_STATE_UPDATE, pushData)
        .then((res) => console.log("Tender State Update posted", res.data));
      reset();
    }
  };

  const activateTender = async () => {
    let pushData = {};
    let changes = { round2: { value: "Round 1 Prices" } };
    pushData["changesBackend"] = changes;
    pushData["tenderID"] = tenders;
    pushData["reactivate"] = "True";
    pushData["user"] = tenderStatus;
    if (window.confirm("Do you really want to reactivate the tender?")) {
      await axios
        .post(API_URL_STATE_UPDATE, pushData)
        .then((res) => console.log("Tender State Update posted", res.data));
      reset();
    }
  };

  const deleteTender = async () => {
    let delData = { tender_id: tenders };
    if (window.confirm("Do you really want to delete the tender?")) {
      if (window.confirm("Are you really sure?")) {
        await axios
          .post(API_URL_TENDERS_DELETE, delData)
          .then((res) => console.log("Tender Deleted", res.data));
        reset();
      }
    }
  };

  const autoGroupColumnDef = useMemo(() => {
    return {
      // group columns configured to use the Group Column Filter
      filter: "`Company Name`",
    };
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid item xs={2}></Grid>
      {/*  HHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH*/}
      {tenderState && (
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={0.5}></Grid>

          {tenderState && (
            <Grid item xs={12} align="left">
              <Button
                variant="outlined"
                size="small"
                align="left"
                color="inherit"
                onClick={(e) => {
                  window.history.replaceState(
                    null,
                    "",
                    window.location.pathname
                  );
                  reset();
                }}
              >
                ← Back
              </Button>
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography align="left">
              Tender for Company '{company}' with ID {tenders} Selected!
            </Typography>
          </Grid>
          {requested_capacity && (
            <Grid item xs={12}>
              <Typography align="left" fontWeight={600}>
                Capacity requested for this tender is {requested_capacity}
              </Typography>
            </Grid>
          )}
          <Grid item xs={10}></Grid>

          {tenderStatus !== "Round 2 Feedback" &&
            tenderStatus.indexOf("Rejected") < 0 &&
            tenderStatus !== "Round 3 Feedback" && (
              <FullScreen onChange={reportChange} handle={fullScreenHandle1}>
                <div
                  style={gridStyle}
                  ref={ref}
                  id="fullScreen-wrap"
                  className="ag-theme-alpine asdf"
                >
                  <TableControls
                    table={gridRef1}
                    fullScreenHandle={fullScreenHandle1}
                    showImport={true}
                    showExport={true}
                    tenders={tenders}
                    user={props.user}
                  />
                  <AgGridReact
                    autoGroupColumnDef={autoGroupColumnDef}
                    autoSizeStrategy={"fitCellContents"}
                    onGridColumnsChanged={() => onColumnChange()}
                    ref={gridRef1} // Ref for accessing Grid's API
                    rowData={tenderData} // Row Data for Rows
                    columnDefs={gridCols} // Column Defs for Columns
                    onCellValueChanged={onCellValueChanged}
                    gridOptions={gridOptions}
                    pagination={true}
                    paginationAutoPageSize={true}
                  />
                </div>
              </FullScreen>
            )}

          {(tenderStatus === "Round 2 Feedback" ||
            tenderStatus === "Round 3 Feedback") && (
            <FullScreen onChange={reportChange} handle={fullScreenHandle2}>
              <Grid item xs={12}>
                <div style={gridStyle} className="ag-theme-alpine">
                  <TableControls
                    table={gridRef2}
                    fullScreenHandle={fullScreenHandle2}
                    user={props.user}
                  />
                  <AgGridReact
                    onGridColumnsChanged={() => onColumnChange()}
                    autoSizeStrategy={"fitCellContents"}
                    ref={gridRef2} // Ref for accessing Grid's API
                    rowData={tenderData} // Row Data for Rows
                    columnDefs={gridColsF} //laneColumnDefinitionRound2} // Column Defs for Columns
                    onCellValueChanged={onCellValueChanged}
                    gridOptions={gridOptions}
                    pagination={true}
                    paginationAutoPageSize={true}
                  />
                </div>
              </Grid>
            </FullScreen>
          )}
        </Grid>
      )}

      {!tenderState && (
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={0.5}></Grid>
          <Grid item xs={12}>
            <Typography variant="h6" align="left">
              Tenders with Actions to be Done
            </Typography>
          </Grid>
          <Grid item xs={0}></Grid>
          <Grid item xs={12}>
            <div style={gridStyle} className="ag-theme-alpine">
              <AgGridReact
                autoSizeStrategy={"fitCellContents"}
                onGridColumnsChanged={() => onColumnChange()}
                ref={gridRef3} // Ref for accessing Grid's API
                rowData={tenderData1} // Row Data for Rows
                columnDefs={gridCols} // Column Defs for Columns
                onRowDoubleClicked={getTenderData}
                pagination={true}
                paginationAutoPageSize={true}
              />
            </div>
          </Grid>
        </Grid>
      )}
      {!tenderState && user.isAdmin && (
        <FullScreen onChange={reportChange} handle={fullScreenHandle4}>
          <Grid
            container
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item xs={12}></Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="left">
                Accepted, Closed and Rejected Tenders
              </Typography>
            </Grid>
            <Grid item xs={0.5}></Grid>
            <Grid item xs={12}>
              <div style={gridStyle} className="ag-theme-alpine">
                <TableControls
                  table={gridRef4}
                  fullScreenHandle={fullScreenHandle4}
                  user={props.user}
                />
                <AgGridReact
                  onGridColumnsChanged={() => onColumnChange()}
                  autoSizeStrategy={"fitCellContents"}
                  ref={gridRef4} // Ref for accessing Grid's API
                  rowData={tenderData2} // Row Data for Rows
                  columnDefs={gridCols} // Column Defs for Columns
                  onRowDoubleClicked={getTenderData}
                  pagination={true}
                  paginationAutoPageSize={true}
                />
              </div>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </FullScreen>
      )}
      {tenderState && (
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={12}></Grid>

          {/* New Line */}
          <Grid item xs={9}>
            {!user.isAdmin && (
              <Typography align="right" variant="h6">
                You are done with the prices? Mark them as completed and send
                them to the central tender management!
              </Typography>
            )}
          </Grid>
          <Grid item xs={6}></Grid>
          {/* {(tenderStatus !== "Accepted") && (tenderStatus !== "Closed") && (tenderStatus.indexOf("Rejected") < 0) && ( */}
          {(tenderStatus === "Round 1 Prices" ||
            tenderStatus === "Round 2 Prices" ||
            tenderStatus === "Round 3 Prices" ||
            tenderStatus === "Round 2 Feedback" ||
            tenderStatus === "Round 3 Feedback") && (
            // (props.user !== "admin" && props.user !== "tender-management") && (
            <Grid item xs={3}></Grid>
          )}
          {/* {(tenderStatus !== "Accepted") && (tenderStatus !== "Closed") && (tenderStatus.indexOf("Rejected") < 0) && ( */}

          {(tenderStatus === "Round 1 Prices" ||
            tenderStatus === "Round 2 Prices" ||
            tenderStatus === "Round 3 Prices" ||
            tenderStatus === "Round 2 Feedback" ||
            tenderStatus === "Round 3 Feedback") &&
            props.user !== "admin" &&
            props.user !== "tender-management" && (
              <>
                <Grid item xs={2}>
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={confirmPrices}
                  >
                    Confirm Prices
                  </Button>
                </Grid>
              </>
            )}
          {tenderStatus === "Accepted" && (
            <Grid item xs={2}>
              <Button
                variant="outlined"
                size="medium"
                onClick={closeTender}
                color="inherit"
              >
                Close Tender
              </Button>
            </Grid>
          )}
          {tenderStatus === "Closed" && (
            <Grid item xs={2}>
              <Button
                variant="outlined"
                size="medium"
                onClick={deleteTender}
                color="inherit"
              >
                Delete Tender
              </Button>
            </Grid>
          )}
          <Grid item xs={7}></Grid>
          <Grid item xs={2}>
            {user.isAdmin &&
              (tenderStatus === "Round 1 Finalized" ||
                tenderStatus === "Round 2 Finalized" ||
                tenderStatus === "Round 3 Finalized") && (
                <Stack direction="column" spacing={1} alignItems="center">
                  <FormControl>
                    <RadioGroup
                      defaultValue="close"
                      value={checked.toString()}
                      onChange={handleChangeSwitch}
                    >
                      <FormControlLabel
                        value="close0"
                        control={<Radio />}
                        label="Close Tender"
                      />

                      {tenderStatus === "Round 1 Finalized" && (
                        <FormControlLabel
                          value="advance2"
                          control={<Radio />}
                          label="Advance Tender to Round 2"
                        />
                      )}
                      {tenderStatus === "Round 2 Finalized" && (
                        <FormControlLabel
                          value="advance3"
                          control={<Radio />}
                          label="Advance Tender to Round 3"
                        />
                      )}
                      {(tenderStatus === "Round 2 Finalized" ||
                        tenderStatus === "Round 3 Finalized") &&
                        checksComplete && (
                          <FormControlLabel
                            value="accept0"
                            control={<Radio />}
                            label="Accept Tender"
                          />
                        )}
                    </RadioGroup>
                  </FormControl>
                  {(tenderStatus === "Round 2 Finalized" ||
                    tenderStatus === "Round 3 Finalized") &&
                    !checksComplete && (
                      <Typography>Not All Checks Completed</Typography>
                    )}
                </Stack>
              )}
            {user.isAdmin &&
              (tenderStatus === "Round 1 Prices" ||
                tenderStatus === "Round 2 Prices" ||
                tenderStatus === "Round 3 Prices" ||
                tenderStatus === "Round 2 Feedback" ||
                tenderStatus === "Round 3 Feedback") && (
                <Typography align="left"></Typography>
              )}
          </Grid>
          {tenderStatus.indexOf("Rejected") < 0 && (
            <Grid item xs={2}>
              {user.isAdmin &&
                (tenderStatus === "Round 1 Finalized" ||
                  tenderStatus === "Round 2 Finalized" ||
                  tenderStatus === "Round 3 Finalized") && (
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={updateState}
                  >
                    {checked.substring(0, checked.length - 1)} Tender
                  </Button>
                )}
              {user.isAdmin &&
                (tenderStatus === "Round 1 Prices" ||
                  tenderStatus === "Round 2 Prices" ||
                  tenderStatus === "Round 3 Prices" ||
                  tenderStatus === "Round 2 Feedback" ||
                  tenderStatus === "Round 3 Feedback") && (
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={closeTender}
                    color="inherit"
                  >
                    Close Tender
                  </Button>
                )}
            </Grid>
          )}
          <Grid item xs={12}></Grid>
        </Grid>
      )}
      {tenderState && tenderStatus.indexOf("Rejected") >= 0 && (
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={9}></Grid>
          <Grid item xs={2}>
            <Button
              variant="outlined"
              size="medium"
              onClick={activateTender}
              color="inherit"
            >
              Reactivate Tender
            </Button>
          </Grid>
          <Grid item xs={9}></Grid>
          <Grid item xs={2}>
            <Button
              variant="outlined"
              size="medium"
              onClick={deleteTender}
              color="inherit"
            >
              Delete Tender
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
export default LanePricing;
